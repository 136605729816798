import { combineReducers } from 'redux';
import { message } from 'antd';
import global, { initialState as globalInitialState } from './global/globalReducer';
import user, { initialState as userInitialState } from './user/userReducer';
import region, { initialState as regionInitialState } from './region/regionReducer';
import favourite, { initialState as favouriteInitialState } from './favourite/favouriteReducer';
import bookings, { initialState as bookingsInitialState } from './bookings/bookingsReducer';
import availability, { initialState as availabilityInitialState } from './availability/availabilityReducer';
import centre, { initialState as centreInitialState } from './centre/centreReducer';

const { CLEAR_SESSION } = require('./user/userActions').constants;
/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    global,
    user,
    region,
    favourite,
    bookings,
    availability,
    centre,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        message.error(action.payload);
    }

    if (action.type === CLEAR_SESSION) {
        const newState = {
            global: globalInitialState,
            user: userInitialState,
            region: regionInitialState,
            favourite: favouriteInitialState,
            bookings: bookingsInitialState,
            availability: availabilityInitialState,
            centre: centreInitialState,
        };

        // eslint-disable-next-line no-undef
        window.location.href = '/';

        return newState;
    }

    return appReducers(state, action);
};

export default rootReducer;
