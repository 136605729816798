import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    POST_BOOKING_REQUEST: null,
    POST_BOOKING_SUCCESS: null,
    POST_BOOKING_FAILURE: null,

    GET_PATIENT_BOOKINGS_DETAILS_REQUEST: null,
    GET_PATIENT_BOOKINGS_DETAILS_SUCCESS: null,
    GET_PATIENT_BOOKINGS_DETAILS_FAILURE: null,
    CLEAN_UP_BOOKING_DATA: null,
    CLEAN_UP_BOOKING_RESPONSE: null,

    STORE_MESSAGE_ID: null,
});

export const {
    postBookingRequest,
    postBookingSuccess,
    postBookingFailure,
} = createActions(
    constants.POST_BOOKING_REQUEST,
    constants.POST_BOOKING_SUCCESS,
    constants.POST_BOOKING_FAILURE,
);

export const {
    getPatientBookingsDetailsRequest,
    getPatientBookingsDetailsSuccess,
    getPatientBookingsDetailsFailure,
} = createActions(
    constants.GET_PATIENT_BOOKINGS_DETAILS_REQUEST,
    constants.GET_PATIENT_BOOKINGS_DETAILS_SUCCESS,
    constants.GET_PATIENT_BOOKINGS_DETAILS_FAILURE,
);

export const {
    cleanUpBookingData,
    cleanUpBookingResponse,
} = createActions(
    constants.CLEAN_UP_BOOKING_DATA,
    constants.CLEAN_UP_BOOKING_RESPONSE,
);

export const {
    storeMessageId,
} = createActions(
    constants.STORE_MESSAGE_ID,
);
