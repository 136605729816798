import React from 'react';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import Routes from './core/routes';
import history from './core/utils/history';
import debug from './core/utils/debug';

import './styles/style.css';
/** Actions: The necessary actions for dispatching our bootstrap values */
import { setVersion } from './core/global/globalActions';

/** The version of the app but not displayed yet */
import pack from '../package.json';

/** configureStore */
import configureStore from './core/store';
import SessionManager from './components/SessionManager';

// Get bugsnag error wrapper
const ErrorWrapper = debug.getWrapper();

const globalStore = configureStore({});
const { store } = globalStore;

const VERSION = pack.version;

store.dispatch(setVersion(VERSION));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ErrorWrapper>
            <Provider store={store}>
                <Router
                    history={history}
                    basename="/">
                    <SessionManager />
                    <Routes />
                </Router>
            </Provider>
        </ErrorWrapper>
    </React.StrictMode>,
);
