import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {

    registerSuccess,
    registerFailure,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

    clearSession,
} from './userActions';

import app from '../constants';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = app;

const {
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const { token } = action.data;
    const authDate = new Date();

    Cookies.set(AUTH_KEY, token, {
        path: '/',
        secure: true,
        sameSite: 'Strict',
        expires: 1 / 96,
    });

    Cookies.set(LOCALSTORAGE_EXPIRES_KEY, authDate.toISOString(), {
        path: '/',
        secure: true,
        sameSite: 'Strict',
        expires: 1 / 96,
    });
}

function* register({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/register',
            body: {
                email: payload.data.email,
                password: payload.data.password,
                first_name: payload.data.firstName,
                last_name: payload.data.lastName,
            },
        });
        history.push('/');
        yield put(registerSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'login',
            body: { email: action.payload.email, password: action.payload.password },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));
            history.push('/dashboard');
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data?.errors?.email : e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/logout',
            body: {},
        });
        yield put(clearSession());
        yield put(logoutSuccess(response));
        history.push('/');
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: { email: payload.email },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('A password reset email has been requested, if an account exists you will receive an email shortly.');
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response.data.errors.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'reset-password',
            body: {
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
    ]);
}
