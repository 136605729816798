import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import sessionExpiryMiddleware from './sessionExpiryMiddleware';
/**
* Reducer
*/
import reducer from './reducers';

/**
* Sagas
*/
import rootSagas from './sagas';

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

    const store = createStore(
        reducer,
        composeEnhancers(applyMiddleware(
            sagaMiddleware,
            thunk,
            sessionExpiryMiddleware,
        )),
    );

    sagaMiddleware.run(rootSagas);

    return { store };
}
