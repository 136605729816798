import React from 'react';

import { CoreRouter } from './utils/coreRouter';
import BeginBooking from '../pages/BeginBooking';
import Security from '../pages/Security';
import Appointment from '../pages/Appointment';
import Availability from '../pages/Availability';
import AttachDocument from '../pages/AttachDocument';
import BookingResult from '../pages/BookingResult';

const Routes = () => (
    <CoreRouter routes={[
        { path: '/', component: BeginBooking, type: 'default' },
        { path: '/begin-booking', component: BeginBooking, type: 'default' },
        { path: '/security', component: Security, type: 'default' },
        { path: '/appointment', component: Appointment, type: 'restricted' },
        { path: '/availability', component: Availability, type: 'restricted' },
        { path: '/attach-document', component: AttachDocument, type: 'restricted' },
        { path: '/booking-result', component: BookingResult, type: 'restricted' },
    ]} />
);

export default Routes;
