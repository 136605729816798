import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchApi } from '../utils/api';

import {
    postBookingSuccess,
    postBookingFailure,

    getPatientBookingsDetailsSuccess,
    getPatientBookingsDetailsFailure,

    storeMessageId,
} from './bookingsActions';

const {
    POST_BOOKING_REQUEST,
    GET_PATIENT_BOOKINGS_DETAILS_REQUEST,
} = require('./bookingsActions').constants;

function* postBookingRequest({ payload }) {
    const { bookingRequests } = payload;

    try {
        const responses = yield call(fetchApi, {
            method: 'POST',
            url: 'serviceRequests/postBookService',
            body: bookingRequests,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        yield put(postBookingSuccess(responses?.data));
    } catch (e) {
        yield put(postBookingFailure(e.response ? e.response.data.message : 'Something went wrong'));
    }
}

function* getPatientBookingsDetails({ payload }) {
    const { messageId, methodToBook, surname, dob } = payload;

    const dobFormatted = dob.split('/').reverse().join('-');

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `patients/CheckPatientDetails?messageId=${messageId}&methodToBook=${methodToBook}&surname=${surname}&dob=${dobFormatted}`,
        });

        yield put(getPatientBookingsDetailsSuccess({ ...response?.data, methodToBook }));
        if (response?.data?.MessageId) {
            yield put(storeMessageId(response.data.MessageId));
        }
    } catch (e) {
        yield put(getPatientBookingsDetailsFailure('Not found booking details'));
    }
}

export default function* clinicianSagas() {
    yield* [
        takeEvery(POST_BOOKING_REQUEST, postBookingRequest),
        takeEvery(GET_PATIENT_BOOKINGS_DETAILS_REQUEST, getPatientBookingsDetails),
    ];
}
