/* eslint-disable default-param-last */
/* eslint-disable no-fallthrough */
import { Record } from 'immutable';
import { BOOKING_STATUS_SUCCESS } from '../utils/values';

const {
    GET_PATIENT_BOOKINGS_DETAILS_REQUEST,
    GET_PATIENT_BOOKINGS_DETAILS_SUCCESS,
    GET_PATIENT_BOOKINGS_DETAILS_FAILURE,

    POST_BOOKING_REQUEST,
    POST_BOOKING_SUCCESS,
    POST_BOOKING_FAILURE,

    CLEAN_UP_BOOKING_DATA,
    CLEAN_UP_BOOKING_RESPONSE,
    STORE_MESSAGE_ID,
} = require('./bookingsActions').constants;

const InitialState = Record({
    patientBookingsDetails: {},
    modalityData: [],
    isFetching: false,
    error: null,
    fieldValues: {},
    serviceIds: {},
    bookingResponses: {},
    isConfirmed: false,
    isBookingSuccess: false,
    messageId: null,
});

export const initialState = new InitialState();

export default function bookingReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case STORE_MESSAGE_ID:
        return state.set('messageId', payload);

    case POST_BOOKING_REQUEST:
    case GET_PATIENT_BOOKINGS_DETAILS_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case GET_PATIENT_BOOKINGS_DETAILS_SUCCESS: {
        const { Modality: modality, MessageID: messageId, PatientID: patientId, SecretaryEmail: secretaryEmail, methodToBook } = payload;

        const modalityData = modality.map((data) => {
            const {
                ModalityID,
                ModalityName,
                PortalServices,
            } = data;
            return {
                modalityId: ModalityID,
                modalityName: ModalityName,
                portalServices: PortalServices.map((service) => {
                    const {
                        CCOrderID,
                        PortalServiceRequestID,
                        PortalServiceRequestName,
                        CCServiceRequestID,
                        CCProcedureID,
                        DateTimeAdded,
                        Status,
                        StatusDescription,
                    } = service;
                    return {
                        ccOrderId: CCOrderID,
                        portalServiceRequestId: PortalServiceRequestID,
                        portalServiceRequestName: PortalServiceRequestName,
                        ccServiceRequestId: CCServiceRequestID,
                        ccProcedureId: CCProcedureID,
                        dateTimeAdded: DateTimeAdded,
                        status: Status,
                        statusDescription: StatusDescription,
                    };
                }),
            };
        });

        const serviceIds = modalityData.reduce((acc, modalityItem) => {
            const { modalityId, portalServices } = modalityItem;
            acc[modalityId] = portalServices.map(service => service.portalServiceRequestId);
            return acc;
        }, {});

        const patientData = {
            messageId,
            patientId,
            secretaryEmail,
            methodToBook,
        };

        const isBookingSuccess = modalityData.every(item => item.portalServices.every(service => service.status === BOOKING_STATUS_SUCCESS));

        return state
            .set('isFetching', false)
            .set('patientBookingsDetails', patientData)
            .set('modalityData', modalityData)
            .set('isConfirmed', true)
            .set('serviceIds', serviceIds)
            .set('isBookingSuccess', isBookingSuccess);
    }

    case POST_BOOKING_SUCCESS: {
        const {
            MessageID,
            PatientID,
            Date,
            Status,
            Modality,
        } = payload;
        const bookingResponses = {
            messageId: MessageID,
            patientId: PatientID,
            date: Date,
            status: Status,
            modality: Modality.map((modality) => {
                const {
                    ModalityID,
                    ModalityName,
                    PortalServices,
                } = modality;
                return {
                    modalityId: ModalityID,
                    modalityName: ModalityName,
                    portalServices: PortalServices.map((service) => {
                        const {
                            CCProcedureID,
                            CCServiceRequestID,
                            PortalServiceRequestID,
                            PortalServiceRequestName,
                            Status: ServiceStatus,
                        } = service;
                        return {
                            ccProcedureId: CCProcedureID,
                            portalServiceRequestId: PortalServiceRequestID,
                            portalServiceRequestName: PortalServiceRequestName,
                            ccServiceRequestId: CCServiceRequestID,
                            serviceStatus: ServiceStatus,
                        };
                    }),
                };
            }),
        };

        return state
            .set('isFetching', false)
            .set('bookingResponses', bookingResponses);
    }

    case POST_BOOKING_FAILURE:
    case GET_PATIENT_BOOKINGS_DETAILS_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    case CLEAN_UP_BOOKING_DATA:
        return state
            .set('isFetching', false)
            .set('error', null)
            .set('patientBookingsDetails', {})
            .set('messageId', null);

    case CLEAN_UP_BOOKING_RESPONSE:
        return state
            .set('bookingResponses', {});

    default:
        return state;

    }
}
