import { clearSession } from './user/userActions';

const SESSION_TIMEOUT = 10 * 60 * 1000;
let lastActivityTimestamp = Date.now();

const updateLastActivity = () => {
    lastActivityTimestamp = Date.now();
};

const sessionExpiryMiddleware = (store) => {
    const events = ['mousemove', 'keydown', 'click'];
    events.forEach((event) => {
        // eslint-disable-next-line no-undef
        window.addEventListener(event, updateLastActivity);
    });

    setInterval(() => {
        const now = Date.now();

        if (now - lastActivityTimestamp > SESSION_TIMEOUT) {
            store.dispatch(clearSession());
        }
    }, 1000);

    return (next) => (action) => {
        updateLastActivity();
        return next(action);
    };
};

export default sessionExpiryMiddleware;
