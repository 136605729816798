import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    SAVE_USER_DATA_REQUEST,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    SET_USER_LOCATION,
} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    userData: {},
    userLocation: null,
});

export const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
// eslint-disable-next-line default-param-last
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case RESET_PASSWORD_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', (payload && payload.user ? payload.user : {}))
            .set('isLoggedIn', true);

    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', {})
            .set('isLoggedIn', false);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    case REGISTER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case REGISTER_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', (payload && payload.user ? payload.user : {}));

    case SAVE_USER_DATA_REQUEST: {
        const { userData } = state;

        return state.set('isFetching', false)
            .set('userData', (payload && payload.data && payload.data.user ? payload.data.user : userData));
    }

    case RESET_PASSWORD_SUCCESS:
        return state.set('isFetching', false);

    case REGISTER_FAILURE:
    case RESET_PASSWORD_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SET_USER_LOCATION:
        return state.set('userLocation', payload);

    default:
        return state;

    }
}
