import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { clearSession } from '../../core/user/userActions';
import app from '../../core/constants';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = app;

const SessionManager = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const INACTIVITY_TIMEOUT = 600000;
    const CHECK_INTERVAL = 300000;
    const { patientBookingsDetails } = useSelector((state) => state.bookings);

    useEffect(() => {
        if (!isEmpty(patientBookingsDetails)) {
            const sessionStartTime = Date.now();
            const interval = setInterval(() => {
                const sessionEndTime = Date.now();
                const diff = sessionEndTime - sessionStartTime;

                if (diff > INACTIVITY_TIMEOUT) {
                    const { messageId, methodToBook } = patientBookingsDetails;

                    navigate('/security', { state: { messageId, methodToBook } });

                    dispatch(clearSession());

                    Cookies.remove(AUTH_KEY, { path: '/' });
                    Cookies.remove(LOCALSTORAGE_EXPIRES_KEY, { path: '/' });

                }
                return null;
            }, CHECK_INTERVAL);

            return () => clearInterval(interval);
        }
        return undefined;
    }, [patientBookingsDetails, dispatch, navigate]);

    return null;
};

export default SessionManager;
